import React from 'react';
import { Link } from 'react-router-dom';
import SearchComponent from '../components/SearchComponent';

const CitiesScreen = () => {
  return (
    <>
      <SearchComponent />
      <div className="container" style={{ marginTop: '-137px' }}>
        <h1 style={{ margin: '25px 16px 16px', color: 'white', textShadow: '0px 0px 5px #1e202b' }}>Şehirler</h1>
        <div className="cities-container">
          <Link className="city-card" to="/sehir/Adana">
            <h2>Adana</h2>
          </Link>
          <Link className="city-card" to="/sehir/Adıyaman">
            <h2>Adıyaman</h2>
          </Link>
          <Link className="city-card" to="/sehir/Afyonkarahisar">
            <h2>Afyonkarahisar</h2>
          </Link>
          <Link className="city-card" to="/sehir/Ağrı">
            <h2>Ağrı</h2>
          </Link>
          <Link className="city-card" to="/sehir/Aksaray">
            <h2>Aksaray</h2>
          </Link>
          <Link className="city-card" to="/sehir/Amasya">
            <h2>Amasya</h2>
          </Link>
          <Link className="city-card" to="/sehir/Ankara">
            <h2>Ankara</h2>
          </Link>
          <Link className="city-card" to="/sehir/Antalya">
            <h2>Antalya</h2>
          </Link>
          <Link className="city-card" to="/sehir/Ardahan">
            <h2>Ardahan</h2>
          </Link>
          <Link className="city-card" to="/sehir/Artvin">
            <h2>Artvin</h2>
          </Link>
          <Link className="city-card" to="/sehir/Aydın">
            <h2>Aydın</h2>
          </Link>
          <Link className="city-card" to="/sehir/Balıkesir">
            <h2>Balıkesir</h2>
          </Link>
          <Link className="city-card" to="/sehir/Bartın">
            <h2>Bartın</h2>
          </Link>
          <Link className="city-card" to="/sehir/Batman">
            <h2>Batman</h2>
          </Link>
          <Link className="city-card" to="/sehir/Bayburt">
            <h2>Bayburt</h2>
          </Link>
          <Link className="city-card" to="/sehir/Bilecik">
            <h2>Bilecik</h2>
          </Link>
          <Link className="city-card" to="/sehir/Bingöl">
            <h2>Bingöl</h2>
          </Link>
          <Link className="city-card" to="/sehir/Bitlis">
            <h2>Bitlis</h2>
          </Link>
          <Link className="city-card" to="/sehir/Bolu">
            <h2>Bolu</h2>
          </Link>
          <Link className="city-card" to="/sehir/Burdur">
            <h2>Burdur</h2>
          </Link>
          <Link className="city-card" to="/sehir/Bursa">
            <h2>Bursa</h2>
          </Link>
          <Link className="city-card" to="/sehir/Çanakkale">
            <h2>Çanakkale</h2>
          </Link>
          <Link className="city-card" to="/sehir/Çankırı">
            <h2>Çankırı</h2>
          </Link>
          <Link className="city-card" to="/sehir/Çorum">
            <h2>Çorum</h2>
          </Link>
          <Link className="city-card" to="/sehir/Denizli">
            <h2>Denizli</h2>
          </Link>
          <Link className="city-card" to="/sehir/Diyarbakır">
            <h2>Diyarbakır</h2>
          </Link>
          <Link className="city-card" to="/sehir/Düzce">
            <h2>Düzce</h2>
          </Link>
          <Link className="city-card" to="/sehir/Edirne">
            <h2>Edirne</h2>
          </Link>
          <Link className="city-card" to="/sehir/Elazığ">
            <h2>Elazığ</h2>
          </Link>
          <Link className="city-card" to="/sehir/Erzincan">
            <h2>Erzincan</h2>
          </Link>
          <Link className="city-card" to="/sehir/Erzurum">
            <h2>Erzurum</h2>
          </Link>
          <Link className="city-card" to="/sehir/Eskişehir">
            <h2>Eskişehir</h2>
          </Link>
          <Link className="city-card" to="/sehir/Gaziantep">
            <h2>Gaziantep</h2>
          </Link>
          <Link className="city-card" to="/sehir/Giresun">
            <h2>Giresun</h2>
          </Link>
          <Link className="city-card" to="/sehir/Gümüşhane">
            <h2>Gümüşhane</h2>
          </Link>
          <Link className="city-card" to="/sehir/Hakkari">
            <h2>Hakkari</h2>
          </Link>
          <Link className="city-card" to="/sehir/Hatay">
            <h2>Hatay</h2>
          </Link>
          <Link className="city-card" to="/sehir/Iğdır">
            <h2>Iğdır</h2>
          </Link>
          <Link className="city-card" to="/sehir/Isparta">
            <h2>Isparta</h2>
          </Link>
          <Link className="city-card" to="/sehir/İstanbul">
            <h2>İstanbul</h2>
          </Link>
          <Link className="city-card" to="/sehir/İzmir">
            <h2>İzmir</h2>
          </Link>
          <Link className="city-card" to="/sehir/Kahramanmaraş">
            <h2>Kahramanmaraş</h2>
          </Link>
          <Link className="city-card" to="/sehir/Karabük">
            <h2>Karabük</h2>
          </Link>
          <Link className="city-card" to="/sehir/Karaman">
            <h2>Karaman</h2>
          </Link>
          <Link className="city-card" to="/sehir/Kars">
            <h2>Kars</h2>
          </Link>
          <Link className="city-card" to="/sehir/Kastamonu">
            <h2>Kastamonu</h2>
          </Link>
          <Link className="city-card" to="/sehir/Kayseri">
            <h2>Kayseri</h2>
          </Link>
          <Link className="city-card" to="/sehir/Kırıkkale">
            <h2>Kırıkkale</h2>
          </Link>
          <Link className="city-card" to="/sehir/Kırıkkale">
            <h2>Kırıkkale</h2>
          </Link>
          <Link className="city-card" to="/sehir/Kırklareli">
            <h2>Kırklareli</h2>
          </Link>
          <Link className="city-card" to="/sehir/Kırşehir">
            <h2>Kırşehir</h2>
          </Link>
          <Link className="city-card" to="/sehir/Kocaeli">
            <h2>Kocaeli</h2>
          </Link>
          <Link className="city-card" to="/sehir/Konya">
            <h2>Konya</h2>
          </Link>
          <Link className="city-card" to="/sehir/Kütahya">
            <h2>Kütahya</h2>
          </Link>
          <Link className="city-card" to="/sehir/Malatya">
            <h2>Malatya</h2>
          </Link>
          <Link className="city-card" to="/sehir/Manisa">
            <h2>Manisa</h2>
          </Link>
          <Link className="city-card" to="/sehir/Mardin">
            <h2>Mardin</h2>
          </Link>
          <Link className="city-card" to="/sehir/Mersin">
            <h2>Mersin</h2>
          </Link>
          <Link className="city-card" to="/sehir/Muğla">
            <h2>Muğla</h2>
          </Link>
          <Link className="city-card" to="/sehir/Muş">
            <h2>Muş</h2>
          </Link>
          <Link className="city-card" to="/sehir/Nevşehir">
            <h2>Nevşehir</h2>
          </Link>
          <Link className="city-card" to="/sehir/Niğde">
            <h2>Niğde</h2>
          </Link>
          <Link className="city-card" to="/sehir/Ordu">
            <h2>Ordu</h2>
          </Link>
          <Link className="city-card" to="/sehir/Osmaniye">
            <h2>Osmaniye</h2>
          </Link>
          <Link className="city-card" to="/sehir/Rize">
            <h2>Rize</h2>
          </Link>
          <Link className="city-card" to="/sehir/Sakarya">
            <h2>Sakarya</h2>
          </Link>
          <Link className="city-card" to="/sehir/Samsun">
            <h2>Samsun</h2>
          </Link>
          <Link className="city-card" to="/sehir/Şanlıurfa">
            <h2>Şanlıurfa</h2>
          </Link>
          <Link className="city-card" to="/sehir/Siirt">
            <h2>Siirt</h2>
          </Link>
          <Link className="city-card" to="/sehir/Sinop">
            <h2>Sinop</h2>
          </Link>
          <Link className="city-card" to="/sehir/Sivas">
            <h2>Sivas</h2>
          </Link>
          <Link className="city-card" to="/sehir/Şırnak">
            <h2>Şırnak</h2>
          </Link>
          <Link className="city-card" to="/sehir/Tekirdağ">
            <h2>Tekirdağ</h2>
          </Link>
          <Link className="city-card" to="/sehir/Tokat">
            <h2>Tokat</h2>
          </Link>
          <Link className="city-card" to="/sehir/Trabzon">
            <h2>Trabzon</h2>
          </Link>
          <Link className="city-card" to="/sehir/Tunceli">
            <h2>Tunceli</h2>
          </Link>
          <Link className="city-card" to="/sehir/Uşak">
            <h2>Uşak</h2>
          </Link>
          <Link className="city-card" to="/sehir/Van">
            <h2>Van</h2>
          </Link>
          <Link className="city-card" to="/sehir/Yalova">
            <h2>Yalova</h2>
          </Link>
          <Link className="city-card" to="/sehir/Yozgat">
            <h2>Yozgat</h2>
          </Link>
          <Link className="city-card" to="/sehir/Zonguldak">
            <h2>Zonguldak</h2>
          </Link>
        </div>
      </div>
    </>
  );
};

export default CitiesScreen;
