import React from 'react';
import WeatherComponent from './WeatherComponent';
import NewsComponent from './NewsComponent';

export default function HomeComponent() {
  return (
    <>
      <WeatherComponent cityName="İstanbul" />
      <NewsComponent />
    </>
  );
}
