import React, { useState } from 'react';
import logo from '../images/logo.png';
import { Link, useLocation } from 'react-router-dom';

export default function TopBarComponent() {
  // State to handle the visibility of the mobile navigation
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const location = useLocation();

  const isActive = (path) => location.pathname === path;
  // Toggle the state when the button is clicked
  const handleMenuToggle = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  return (
    <div className="site-header">
      <div className="container">
        <Link to="/" className="branding">
          <img src={logo} alt="WeatherTurkey" className="logo" />
          <div className="logo-type">
            <h1 className="site-title">WeatherTurkey</h1>
            <small className="site-description">Türkiye'nin Hava Durumu</small>
          </div>
        </Link>

        <div className="main-navigation">
          <button type="button" className="menu-toggle" onClick={handleMenuToggle}>
            <i className="fa fa-bars"></i>
          </button>
          <ul className="menu">
            <li className={`menu-item ${isActive('/') ? 'current-menu-item' : ''}`}>
              <Link to="/">Ana Sayfa</Link>
            </li>
            <li className={`menu-item ${isActive('/sehirler') ? 'current-menu-item' : ''}`}>
              <Link to="/sehirler">Şehirler</Link>
            </li>
            <li className={`menu-item ${isActive('/iletisim') ? 'current-menu-item' : ''}`}>
              <Link to="/iletisim">İletişim</Link>
            </li>
          </ul>
        </div>

        <div className={`mobile-navigation ${isMobileNavOpen ? 'open' : ''}`}>
          <ul className="menu">
            <li className={`menu-item ${isActive('/') ? 'current-menu-item' : ''}`}>
              <Link to="/" onClick={handleMenuToggle}>
                Ana Sayfa
              </Link>
            </li>
            <li className={`menu-item ${isActive('/sehirler') ? 'current-menu-item' : ''}`}>
              <Link to="/sehirler" onClick={handleMenuToggle}>
                Şehirler
              </Link>
            </li>
            <li className={`menu-item ${isActive('/iletisim') ? 'current-menu-item' : ''}`}>
              <Link to="/iletisim" onClick={handleMenuToggle}>
                İletişim
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
