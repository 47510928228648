import React, { useState } from 'react';
import SearchComponent from '../components/SearchComponent';
import { sendContact } from '../utils/api';
export default function ContactScreen() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (name && email && message) {
      const response = await sendContact({ name, email, message });
      if (response) {
        alert('Mesajınız kaydedildi. En kısa sürede size geri dönüş yapılacaktır.');
        setName('');
        setEmail('');
        setMessage('');
      } else {
        alert('Mesajınız kaydedilemedi. Lütfen daha sonra tekrar deneyiniz.');
      }
    } else {
      alert('Lütfen tüm alanları doldurunuz.');
    }
  };
  return (
    <main className="main-content">
      <SearchComponent />
      <div style={{ padding: '30px 0' }}>
        <div className="container">
          <div className="col-md-offset-1">
            <h2 className="section-title">Bizimle iletişime geçin</h2>
            <p>Sorularınız mı var? Bizimle iletişime geçmek için formu doldurun, size en kısa sürede geri dönelim.</p>
            <form action="#" className="contact-form">
              <div className="row">
                <div className="col-md-6" style={styles.input}>
                  <input
                    type="text"
                    placeholder="Adınız..."
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    required
                  />
                </div>
                <div className="col-md-6" style={styles.input}>
                  <input
                    type="text"
                    placeholder="Email adresiniz..."
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                  />
                </div>
              </div>
              <textarea
                name=""
                placeholder="Mesajınız..."
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                required
              />
              <div className="text-right" style={styles.input}>
                <input type="submit" placeholder="Gönder" value="Gönder" onClick={handleSubmit} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}

const styles = {
  input: {
    marginTop: 10,
    marginBottom: 20,
  },
};
