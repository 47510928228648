import React from 'react';
import backgroundImage from '../images/back.jpg';
export default function SearchComponent() {
  return (
    <div
      className="hero"
      style={{
        backgroundImage: 'url(' + backgroundImage + ')',
      }}
    ></div>
  );
}
