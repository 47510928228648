import React, { useState, useEffect } from 'react';

export default function NewsComponent() {
  const [news, setNews] = useState([]);

  useEffect(() => {
    // TRT Haber'in RSS feed'ine bağlanarak haberleri çekiyoruz
    fetch('https://www.mynet.com/haber/rss/sondakika')
      .then((response) => response.text())
      .then((data) => {
        // XML verisini parse edip, haber başlıklarını alıyoruz
        const parser = new DOMParser();
        const xml = parser.parseFromString(data, 'text/xml');
        const items = xml.getElementsByTagName('item');

        // Haberleri bir diziye dönüştür
        const newsArray = Array.from(items)
          .map((item) => {
            const image = item.getElementsByTagName('img640x360')[0]?.textContent; // Resim kontrolü
            if (!image) return null; // Eğer resim yoksa haberi eklemeyin
            return {
              title: item.getElementsByTagName('title')[0].textContent,
              link: item.getElementsByTagName('link')[0].textContent,
              pubDate: item.getElementsByTagName('pubDate')[0].textContent,
              image, // Resim varsa haber objesine ekle
            };
          })
          .filter((news) => news !== null); // Sadece null olmayan haberleri döndür

        setNews(newsArray);
      })
      .catch((error) => {
        console.error('Haberleri çekerken hata oluştu:', error);
      });
  }, []);

  return (
    <div className="container">
      <h2 className="section-title">Haber Manşetleri</h2>
      <div className="row" style={{ display: 'flex', flexWrap: 'wrap' }}>
        {news.map((article, index) => (
          <div className="col-md-3 col-sm-6" key={index}>
            <div className="live-camera">
              <a href={article.link} target="_blank" rel="noopener noreferrer">
                {article.image && (
                  <figure className="live-camera-cover">
                    <img src={article.image} alt={article.title} />
                  </figure>
                )}
                <h3 className="location">{article.title}</h3>
                <small className="date">
                  {new Date(article.pubDate).toLocaleString('tr-TR', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </small>
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
