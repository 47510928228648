import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import TopBarComponent from './components/TopBarComponent';
import City from './screens/CityScreen';
import ContactScreen from './screens/ContactScreen';
import CitiesScreen from './screens/CitiesScreen';
import HomeComponent from './components/HomeComponent';
import ErrorComponent from './components/ErrorComponent';

function App() {
  return (
    <Router>
      <PageTitleUpdater />
      <div className="App">
        <TopBarComponent />
        <main>
          <Routes>
            <Route path="/" element={<HomeComponent />} />
            <Route path="/sehirler" element={<CitiesScreen />} />
            <Route path="/iletisim" element={<ContactScreen />} />
            <Route path="/sehir/:cityName" element={<City />} />
            <Route path="*" element={<ErrorComponent />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

function PageTitleUpdater() {
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case '/':
        document.title = 'Weather Turkey';
        break;
      case '/sehirler':
        document.title = 'Şehirler - Weather Turkey';
        break;
      case '/oyunlar':
        document.title = 'Oyunlar - Weather Turkey';
        break;
      case '/iletisim':
        document.title = 'İletişim - Weather Turkey';
        break;
      default:
    }
  }, [location]);

  return null; // Bileşen yalnızca yan etkiyi tetikliyor, herhangi bir JSX döndürmüyor.
}

export default App;
