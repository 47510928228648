import supabase from './supabase';

export const cities = async () => {
  try {
    let { data: citiesData, error } = await supabase.from('cities').select().order('name', { ascending: true });
    if (error) {
      throw error; // Supabase hatalarını yakalayın
    }
    return citiesData;
  } catch (err) {
    console.error('Hata:', err);
    return null; // Hata durumunda null dönebilirsiniz
  }
};

export const sendContact = async (data) => {
  try {
    let { error } = await supabase.from('contact').insert(data);
    if (error) {
      throw error; // Supabase hatalarını yakalayın
    }
    return true;
  } catch (err) {
    console.error('Hata:', err);
    return false; // Hata durumunda null dönebilirsiniz
  }
};
