import React from 'react';
import { useParams } from 'react-router-dom';
import WeatherComponent from '../components/WeatherComponent';
import NewsComponent from '../components/NewsComponent';
export default function CityScreen() {
  const { cityName } = useParams();
  document.title = '' + cityName + ' Hava Durumu - Weather Turkey';
  return (
    <div>
      <WeatherComponent cityName={cityName} />
      <NewsComponent />
    </div>
  );
}
