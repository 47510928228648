/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import umbrella from '../images/icon-umberella.png';
import wind from '../images/icon-wind.png';
import compass from '../images/icon-compass.png';
import SearchComponent from './SearchComponent';
export default function WeatherComponent({ cityName }) {
  const [week, setWeek] = useState([]);
  const [currentDate, setCurrentDate] = useState('');
  const [weather, setWeather] = useState({});
  function temizleTurkceKarakterler(str) {
    const turkceKarakterler = /[şŞıIğĞüÜöÖçÇ]/g;
    const asciiKarakterler = {
      ş: 's',
      Ş: 'S',
      ı: 'i',
      I: 'I',
      ğ: 'g',
      Ğ: 'G',
      ü: 'u',
      Ü: 'U',
      ö: 'o',
      Ö: 'O',
      ç: 'c',
      Ç: 'C',
    };

    let temizlenmisStr = str
      .replace(turkceKarakterler, (match) => asciiKarakterler[match] || match)
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, ''); // Aksan işaretlerini temizleme

    // İlk harfi küçük yapma
    if (temizlenmisStr.length > 0) {
      temizlenmisStr = temizlenmisStr.charAt(0).toLowerCase() + temizlenmisStr.slice(1);
    }

    return temizlenmisStr;
  }
  useEffect(() => {
    const daysOfWeek = ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'];
    const today = new Date().getDay();
    const weekFromToday = [...daysOfWeek.slice(today + 1), ...daysOfWeek.slice(0, today)];
    setWeek(weekFromToday);
    const getToday = new Date();
    const options = { day: 'numeric', month: 'long' }; // Tarihi '6 Oct' formatında gösterir
    const formattedDate = getToday.toLocaleDateString('tr-TR', options);
    setCurrentDate(formattedDate);

    async function fetchWeatherData() {
      const city = 'https://www.mynet.com/hava-durumu/' + temizleTurkceKarakterler(cityName) + '-15-gunluk-hava-durumu';

      try {
        // Web sayfasını fetch ile al
        const response = await fetch(city);
        const html = await response.text();

        // HTML'i parse etmek için DOMParser kullan
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');

        // Hava durumu bilgilerini al
        const temperatureText = doc.querySelector('.icon-celsius-box strong').innerText.trim();
        const temperature = parseFloat(temperatureText.split(' ')[0]);

        const feltTemperature = doc.querySelector('.weather-list-felt').innerText.trim();
        const humidity = doc.querySelector('.weather-list-humidity').innerText.trim();
        const windSpeed = doc.querySelector('.weather-list-wind').innerText.trim();
        const rainProbability = doc.querySelector('.weather-list-possibility').innerText.trim();
        const weatherDescription = doc.querySelector('.weather-report-description h3').innerText.trim();
        const iconSrcToday = doc.querySelector('.mr-5').src;

        // Günlük hava durumu tahmini
        const forecastElements = doc.querySelectorAll('.weather-card-type-accordion');
        const forecast = Array.from(forecastElements).map((element) => {
          const day = element.querySelector('h4.heading').innerText.trim();
          const date = element.querySelector('span').innerText.trim();
          const iconSrc = element.querySelector('img').src;
          const strongElements = element.querySelectorAll('strong');
          const temperatureRangeText = strongElements.length > 1 ? strongElements[1].innerText.trim() : '';
          const temperatureRange = parseFloat(temperatureRangeText.split('°')[0]);
          return {
            day,
            date,
            iconSrc,
            temperatureRange,
          };
        });

        return {
          current: {
            temperature,
            feltTemperature,
            humidity,
            windSpeed,
            rainProbability,
            weatherDescription,
            iconSrcToday,
          },
          forecast,
        };
      } catch (error) {
        console.error('Hata oluştu:', error);
      }
    }

    // Kullanımı
    fetchWeatherData().then((weatherData) => {
      setWeather(weatherData);
    });
  }, [cityName]);
  return (
    <>
      <SearchComponent />
      <div className="forecast-table">
        <div className="container">
          <div className="forecast-container">
            <div className="today forecast">
              <div className="forecast-header">
                <div className="day">Bugün</div>
                <div className="date">{currentDate}</div>
              </div>
              <div className="forecast-content" style={!weather.forecast ? { filter: 'blur(40px)' } : null}>
                <div className="location">{cityName}</div>
                <div className="degree">
                  <div className="num">
                    {weather?.current?.temperature || 29}
                    <sup>o</sup>C
                  </div>
                  <div className="forecast-icon">
                    <img
                      src={
                        weather?.current?.iconSrcToday || 'https://img7.mynet.com.tr/rosetta/havadurumu/icons/01d.svg'
                      }
                      alt="bugün"
                      width={90}
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                  <span>
                    <img src={umbrella} alt="nem" />
                    {weather?.current?.rainProbability || '10%'}
                  </span>
                  <span>
                    <img src={wind} alt="rüzgar" />
                    {weather?.current?.humidity || '47%'}
                  </span>
                  <span>
                    <img src={compass} alt="yön" />
                    {weather?.current?.windSpeed || 'B / 5.32 KM/S'}
                  </span>
                </div>
              </div>
            </div>
            {week.map((day, index) => (
              <div className="forecast" key={index}>
                <div className="forecast-header">
                  <div className="day">{day}</div>
                </div>
                <div className="forecast-content" style={!weather.forecast ? { filter: 'blur(40px)' } : null}>
                  <div className="forecast-icon">
                    {weather.forecast ? (
                      <img key={index} src={weather.forecast[index].iconSrc} alt="" width={48} />
                    ) : (
                      <img src="https://img7.mynet.com.tr/rosetta/havadurumu/icons/01d.svg" alt="" width={48} />
                    )}
                  </div>
                  {weather.forecast ? (
                    <div className="degree">
                      {weather?.forecast[index]?.temperatureRange}
                      <sup>o</sup>C
                    </div>
                  ) : (
                    <div className="degree">
                      28
                      <sup>o</sup>C
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
